<template>
  <div>
    <app-header :routes="routes" />
      <Main>
        <a-tabs
          v-model:activeKey="activeKey"
          :destroyInactiveTabPane="true"
        >
          <a-tab-pane
            v-for="tab in tabs"
            :key="tab.key"
            :tab="$t(`views.backOfficeShipments.tabTitle.${tab.key}`)"
          >
            <a-row>
              <shipments
                :showTitle="false"
                :setStatus="$_.snakeCase(tab.status)"
                :currentView="tab.key"
              />
            </a-row>
          </a-tab-pane>
        </a-tabs>
      </Main>
  </div>
</template>

<script>
import {defineComponent, ref} from 'vue';
import {Main} from '@/components/shared/styledComponents/Main';
import Shipments from '@/components/shipments/Shipments';
import {useRoute} from 'vue-router';

export default defineComponent({
  name: 'BackOfficeShipments',
  components: {
    Main,
    Shipments
  },
  setup() {
    const route = useRoute();

    const tabs = [
      {status: undefined, key: 'all'},
      {status: 'sanctioned', key: 'sanctioned'},
      {status: 'awaitingCancellationApproval', key: 'cancelled'},
      {status: 'awaitingCompletionApproval', key: 'completed'}
    ];

    const activeKey = ref(route.query.currentTab || tabs[0].status);

    const routes = [{
      name: 'BackOfficeShipments',
      breadcrumbName: 'views.shipmentRequest.shipments'
    }];

    return {
      activeKey,
      routes,
      tabs
    };
  }
});
</script>
